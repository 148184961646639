import { chain, memoize, round } from 'lodash'

export function oAssign(from: any, value: any) {
  for (const a in from) {
    if (value[a]) from[a] = value[a]
  }
}

export function dateFormat(timestamp: number, locale: string) {
  return new Date(timestamp * 1000).toLocaleDateString(locale)
}

export function dateTimeFormat(timestamp: number, locale: string) {
  return new Date(timestamp * 1000).toLocaleString(locale)
}

export function getNthDate(nthDays: any, fromDate = new Date()) {
  fromDate.setUTCHours(0, 0, 0)
  fromDate.setTime(fromDate.getTime() + nthDays * 86400000)
  return fromDate
}

export function dateGetMonthYear(timestamp: number, locale: string) {
  return `${new Date(timestamp * 1000).toLocaleDateString(locale, { month: 'long' })} ${new Date(
    timestamp * 1000,
  ).getFullYear()}`
}

export function nbDaysOverdue(timestampDue: number, timestampEnd: number) {
  if (timestampDue === 0) {
    return 0
  } else if (timestampEnd === 0) {
    const nowTimestamp = Date.now() / 1000

    if (timestampDue > nowTimestamp) return 0
    else return daysRange(timestampDue, nowTimestamp)
  } else {
    return daysRange(timestampDue, timestampEnd)
  }
}

export function daysRange(timestampStart: number, timestampEnd: number) {
  // Is it same day ?
  const dateStart = new Date(timestampStart * 1000)
  const dateEnd = new Date(timestampEnd * 1000)
  const dateStartString = `${dateStart.getDate()}/${dateStart.getMonth()}/${dateStart.getFullYear()}`
  const dateEndString = `${dateEnd.getDate()}/${dateEnd.getMonth()}/${dateEnd.getFullYear()}`
  const isSameDay = dateStartString === dateEndString

  if (isSameDay) return 0
  else return round((timestampEnd - timestampStart) / (60 * 60 * 24))
}

export function formatBytes(bytes: number, decimals: number) {
  if (bytes === undefined) return ''
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals || 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export function getInitialForFullName(fullName: string) {
  return fullName
    ? fullName
        .split(' ')
        .map(name => name.substring(0, 1))
        .join('')
    : ''
}

const KEY = 'messages'

export function clearMessages() {
  return localStorage.removeItem(KEY)
}

export async function clearCacheStorage() {
  if (window.caches) {
    await Promise.all(
      chain(await window.caches.keys())
        .map((key: any) => window.caches.delete(key))
        .value(),
    )
  }
}

export function forceHomeReload() {
  const { protocol, hostname, port } = window.location
  const url = [protocol, '//', hostname]
  if (port) url.push(...[':', port])

  const newUrl = `${url.join('')}`
  fetch(url.join(''), { cache: 'reload', headers: { 'Clear-Site-Data': 'storage' } }).finally(() => {
    window.location.href = newUrl
  })
}

export async function hotReload() {
  await clearCacheStorage()
  clearMessages()
  forceHomeReload()
}

export const versionToNumber = memoize((version?: string) =>
  chain(version).defaultTo(0).thru(String).replace(/\./g, '').join('').thru(Number).value(),
)

export function getIconByAttributeType(type: string) {
  switch (type) {
    case 'STRING':
      return 'carbon-string-text'
    case 'NUMBER':
      return 'carbon-string-integer'
    case 'DATE':
      return 'carbon-calendar'
    case 'BOOLEAN':
      return 'carbon-boolean'
    case 'SELECT':
    case 'MULTISELECT':
      return 'carbon-list-dropdown'
    case 'LIST':
      return 'carbon-list'
    case 'URL':
      return 'carbon-link'
    default:
      return 'carbon-list-boxes'
  }
}
