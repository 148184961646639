import Echo from 'laravel-echo'
import { defineStore } from 'pinia'
import { userStore } from '~/stores/user'
import { User } from '~/types/user'
import { Attribute, AttributeTypeLabel } from '~/types/view-elements'
import { isValidAttribute } from '~/utils/attributes'
import { apiStore } from './api'
import { useSockets } from './sockets'

export const ATTRIBUTES_CHANNEL = 'Knowledge.Attributes'

export enum AttributeEvents {
  CREATED = '.knowledge.attribute.created',
  UPDATED = '.knowledge.attribute.updated',
  DELETED = '.knowledge.attribute.deleted',
  RESTORED = '.knowledge.attribute.restored',
}

const SKIPPED_ATTRIBUTE_TYPES = ['PROGRESS']

interface State {
  attributes: {
    list: Array<Attribute>
    canCreate: boolean
  }
  measurementUnits: Array<string>
  attributeTypes: Array<string>
  attributesOptions: Array<{ value: string; label: string }>
  loaded: boolean
  socket: Echo | null
}

export const attributesStore = defineStore({
  id: 'attributes',
  state: (): State => ({
    attributes: {
      list: [],
      canCreate: false,
    },
    measurementUnits: [],
    attributeTypes: [],
    attributesOptions: [],
    loaded: false,
    socket: null,
  }),
  getters: {
    getAttributes(): Attribute[] {
      return this.attributes.list.filter(attr => isValidAttribute(attr.name))
    },
    getAttributeBySlug:
      state =>
      (slug: string): Attribute | undefined => {
        return state.attributes.list.find(attr => attr.slug === slug)
      },
    getSocketClient: async (state): Promise<Echo> => {
      if (state.socket) return state.socket

      const sockets = useSockets()
      state.socket = await sockets.getClient()
      return state.socket
    },
  },
  actions: {
    async reload() {
      this.loaded = false
      return this.loadAttributes()
    },
    async loadAttributes(): Promise<void> {
      const user: User | null = userStore().user
      if (!user || this.loaded) return

      await Promise.all([this.loadAttributesTypes(), this.loadMeasurementUnits()])

      const api = apiStore().api
      const { data, auth } = await api.getAvailableAttributes()
      this.attributes.list = data.filter(attr => !attr.name.startsWith('cx_'))
      this.loaded = true
      this.attributes.canCreate = Boolean(auth.can.create)

      this.subscribe()
    },

    async loadAttributesTypes() {
      const api = apiStore().api
      const attributeTypes = await api.getAttributesTypes()
      this.attributeTypes = Object.values(attributeTypes)
      this.attributesOptions = Object.values(attributeTypes).flatMap(value =>
        SKIPPED_ATTRIBUTE_TYPES.includes(value)
          ? []
          : {
              value,
              label: AttributeTypeLabel[value as keyof typeof AttributeTypeLabel],
            },
      )
    },

    async loadMeasurementUnits() {
      const api = apiStore().api
      const measurementUnits = await api.getUom()
      this.measurementUnits = Object.values(measurementUnits)
    },

    async subscribe() {
      const client = await this.getSocketClient

      client
        .private(ATTRIBUTES_CHANNEL)
        .listen(AttributeEvents.CREATED, (attribute: Attribute) => {
          this.attributes.list.push(attribute)
        })
        .listen(AttributeEvents.UPDATED, (attribute: Attribute) => {
          const index = this.attributes.list.findIndex(attr => attr.id === attribute.id)
          if (index !== -1) {
            this.attributes.list[index] = attribute
          }
        })
        .listen(AttributeEvents.DELETED, ({ id }: { id: string }) => {
          const index = this.attributes.list.findIndex(attr => attr.id === id)
          if (index !== -1) {
            this.attributes.list[index] = {
              ...this.attributes.list[index],
              deleted_at: new Date().toISOString(),
            }
          }
        })
        .listen(AttributeEvents.RESTORED, ({ id }: { id: string }) => {
          const index = this.attributes.list.findIndex(attr => attr.id === id)
          if (index !== -1) {
            this.attributes.list[index] = {
              ...this.attributes.list[index],
              deleted_at: null,
            }
          }
        })
    },
    async unsubscribe() {
      const client = await this.getSocketClient

      client.leave(ATTRIBUTES_CHANNEL)
    },
  },
})
